@import "sass-lib";
@media only screen and (max-width:1780px){
	.wrapper.new{ width: 1130px; }
	.wrap-dana.new{ height: 300px; }
	.btn-knj{ line-height: 50px;  font-size: 16px;}
	.link-forgot{ font-size: 14px; margin-bottom: 15px; }
	.register{ font-size: 14px; margin-bottom: 20px; line-height: 15px;}
	.btn-link-news{ line-height: 40px; font-size: 16px; }
	.wrapper1360{ width: 980px; }
	.login.new{
		padding: 20px 30px 50px; max-height: 440px;
		span{ font-size: 16px; margin-bottom: 25px;}
		label{ margin-bottom: 10px;  }
		input[type='text'], input[type='password'], input[type='email']{ height: 40px; }
		input[type='submit']{ line-height: 40px;  font-size: 16px;}
		&.forgot{
			.right{
				width: 150px;
				span{ font-size: 14px; }
			}
		}
	}
	header.new{
		.line{
			&:before{ width:460px; }
		}
	}
	
}

@media only screen and (max-width:1280px){
	.wrapper.new{ width: 940px; }
	.wrapper1360{ width: 940px; }
	.btn-knj{ line-height: 40px; font-size: 15px; }
	.box-home.new{ 
		margin: 0 -25px; padding: 20px 0 55px;
		.list-box{
			padding: 0 25px;
		}
		.reksa-home {
			 h5{ margin-bottom:15px; padding-bottom: 15px; }
		}
	}
	.wrap-dana.new{ 
		height: 260px;
	}
	.login.new{
		max-height: 380px; padding: 20px 20px 30px;
		span{ margin-bottom: 15px; }
		label{ font-size: 14px; }
		input[type='text'],
		input[type='email'],
		input[type='password']{ font-size: 16px; height: 35px;}
		input[type='submit']{ line-height: 30px; font-size: 14px; }
		.row{
			&:last-child{
				margin-bottom: 5px;
			}
		}
		&.forgot.new{
			left: 20px; right: 20px;
			.left{
				width: 80px;
				span{ 
					font-size: 14px;
					a{ font-size: 14px; }
				}
			}
			.right{
				width: 120px;
				span{ font-size: 14px; }
			}
		}
	}
	.link-forgot{ margin-bottom: 10px; }
	.register{ margin-bottom: 10px; font-size: 14px; line-height: 18px;}
	.btn-link-news{ line-height: 40px; font-size: 15px; }
	.banner-middle{
		.tittle-banner{
			height: 100px;
			h4{ font-size: 18px; }
			span{ font-size:40px;  }
		}
	}
	.middle-content{
		.wrapper{ width: 940px; }
		h2{ font-size: 28px; margin-bottom: 35px; }
		h4{margin-bottom: 10px; font-size: 22px;}
		input[type="text"], input[type="password"], input[type="email"], input[type="file"], textarea{padding-left: 12px; padding-right: 12px;}
		select{padding-left: 12px; padding-right: 37px;}
	}
	.row_list{
        .col{
            &.col_mid_full{width: 100%;}
            &.col_mid_8{width: 80%;}
            &.col_mid_7{width: 70%;}
            &.col_mid_6{width: 60%;}
            &.col_mid_55{width: 55%;}
            &.col_mid_5{width: 50%;}
            &.col_mid_45{width: 45%;}
            &.col_mid_4{width: 40%;}
            &.col_mid_35{width: 35%;}
            &.col_mid_33{width: 33.3333333333333%;}
            &.col_mid_3{width: 30%;}
            &.col_mid_25{width: 25%;}
            &.col_mid_2{width: 20%;}
            &.col_mid_16{width: 16.66666666666666%;}
            &.col_mid_1{width: 10%;}
        }
    }
	footer.new{
		padding-bottom: 30px;
		.right{
			span{ line-height: 16px; }
		}
	}
}